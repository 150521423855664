import React from 'react'
// import Cover1 from '../../images/cover/cover_img_1.jpg';
import "./CoursesFrontPage.css"

const CourcesFrontPage = () => {
    return (
        <div id='courseHome'>
            <div className="contentsCourse">
                <h1 id="headingCourses">The Roots of Education are Bitter, But the Fruit is Sweet</h1>
                <p>Greenfield National College</p>
            </div>
        </div>
    )
}

export default CourcesFrontPage
